import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
  } from "react";
  import { LOCAL_STORAGE_KEYS } from '../utils/CONSTANTS.js'
import { useWizard } from "./wizardContext.js";
import { useItinerary } from "./itineraryContext.js";
import { useChat } from "./chatContext.js";

  
  export const localStorageContext = createContext();
  
  const useProvideLocalStorage = () => {
    const [LocalStorageItems, setLocalStorageitems] = useState([]);

    const wizardData = useWizard() || {};
    const {setWizardItems} = wizardData;

    const itineraryData = useItinerary() || {};
    const {setScheduledDays, setOptionsItems} = itineraryData;

    const setToLS = async (batch, data) => {
      window.localStorage.setItem(batch, JSON.stringify(data));
    }

    const deletefromLS = async (batch) => {
      window.localStorage.removeItem(batch);
    }


    const getFromLS = async (batch) => {
      const values = await window.localStorage.getItem(batch);
      return JSON.parse(values)
    }

    const isInLS = async (batch) => {
      return window.localStorage.getItem(batch);
    }

    const removeAllDataFromLS = async () => {
      Object.values(LOCAL_STORAGE_KEYS).map(async (el) => {
        await deletefromLS(el);
      })
      setWizardItems([]);
      setScheduledDays([]);
      setOptionsItems([]);

    }

    // const userToken = localStorage.getItem('userToken');
    // if (!userToken) {
    //   console.log('localStorage NOT found!');
    //   const expirationTime = 1;
    //   const date = new Date(); // in days
    //   const hour = date.getHours();
    //   const minute = date.getMinutes();
    //   const second = date.getSeconds();
      
    //   const formattedResult = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
    //   setText(formattedResult)
  
    //   localStorage.setItem('userToken', formattedResult);
    // } else {
    //   setText(userToken)
    //   console.log('localStorage found!');
    // }

    return {
        LocalStorageItems, 
        setLocalStorageitems, 
        setToLS, 
        getFromLS,
        isInLS,
        deletefromLS,
        removeAllDataFromLS
    };
  };
  
  export const LocalStorageProvider = ({ children }) => {
    const LocalStorage = useProvideLocalStorage();
    return (
      <localStorageContext.Provider value={LocalStorage}>{children}</localStorageContext.Provider>
    );
  };
  
  export const useLS = () => useContext(localStorageContext);
  