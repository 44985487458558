import React, {
    createContext,
    useContext,
    useEffect,
    useState,
  } from "react";
import {days} from '../utils/mocks.js';
  
  export const itineraryContext = createContext();
  
  const useProvideItinerary = () => {
    const [optionsItems, setOptionsItems] = useState([]);
    const [unwantedOptionsItems, setUnwantedOptionsItems] = useState([]);
    const [scheduledDays, setScheduledDays] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);
  
    return {
        optionsItems, 
        setOptionsItems, 
        scheduledDays,
        setScheduledDays,
        selectedDay,
        setSelectedDay,
        unwantedOptionsItems,
        setUnwantedOptionsItems
    };
  };
  
  export const ItineraryProvider = ({ children }) => {
    const itinerary = useProvideItinerary();
    return (
      <itineraryContext.Provider value={itinerary}>{children}</itineraryContext.Provider>
    );
  };
  
  export const useItinerary = () => useContext(itineraryContext);
  