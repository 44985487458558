import { navigate } from "gatsby";
import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useCallback
  } from "react";
  
  export const layoutContext = createContext();

  const LOCATIONS = [
    {
        type: 'tutorial',
        resolved: false
    },
    {
        text: 'wizard',
        resolved: false
    },
    {
        text: 'options',
        resolved: false
    },
  ] 
  
  const useProvideLayout = () => {
    const [userOptions, setUserOptions] = useState(LOCATIONS);
    const [userLocation, setUserLocation] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [noMoreModal, setNoMoreModal] = useState(false);
    const [placeDetail, setPlaceDetail] = useState(null);
    const [showPDFModal, setShowPDFModal] = useState(false);
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [userLocation]);

    useEffect(() => {
      console.log('zio')
    }, []);

    const toggleMenu = () => {
      if (!showMenu === true) {
        document.body.classList.add('menu-out');
      } else {
        document.body.classList.remove('menu-out');
      }
      setShowMenu(!showMenu)
    }
  
    return {
        userOptions,
        setUserOptions,
        userLocation,
        setUserLocation,
        showMenu,
        setShowMenu, 
        toggleMenu,
        placeDetail,
        setPlaceDetail,
        showModal,
        setShowModal, 
        noMoreModal,
        setNoMoreModal,
        modalData,
        setModalData, 
        showPDFModal,
        setShowPDFModal,
    };
  };
  
  export const LayoutProvider = ({ children }) => {
    const layout = useProvideLayout();
    return (
      <layoutContext.Provider value={layout}>{children}</layoutContext.Provider>
    );
  };
  
  export const useLayout = () => useContext(layoutContext);
  