import React from "react";
import { ChatProvider } from "../contexts/chatContext";
import { LayoutProvider } from "../contexts/layoutContext.js";

import '../assets/styles/global.css'
import { WizardProvider } from "../contexts/wizardContext";
import { LocalStorageProvider } from "../contexts/LsContext";
import { ItineraryProvider } from "../contexts/itineraryContext.js";

export default function RootLayout({ children }) {
    return (
        <>
            
                <ItineraryProvider>
                    <WizardProvider>
                        <LocalStorageProvider>
                            <LayoutProvider>
                                <ChatProvider>
                                    {children}
                                </ChatProvider>
                            </LayoutProvider>
                        </LocalStorageProvider>
                    </WizardProvider>
                </ItineraryProvider>
            
        </>
    );
  }