import transfer_img from '../assets/images/transfer.svg';
import cutleries_img from '../assets/images/cutleries.svg';
import star_img from '../assets/images/star.svg';
import bed_img from '../assets/images/bed.svg';

export const DEFAULT_VALUE = 1500;

export const LOCAL_STORAGE_KEYS = {
    wizard:     'wizardLS',
    options:    'optionsLS',
    itinerary:  'itineraryLS',
    user:       'userLS',
    chat:       'chatLS',
    utility:    'utilityLS',
};

export const CHAT_LIMIT = 20;

export const OPTIONS = {
    cultural: {
        sort: 1,
        icon: star_img,
        color: 'orange',
        text: 'Tappa Culturale',
        title: 'Mete culturali',
    },
    food : {
        sort: 2,
        icon: cutleries_img,
        color: 'blue',
        text: 'Ristorante',
        title: 'Ristoranti',
    },
    transfer : {
        sort: 3,
        icon: transfer_img,
        color: 'red',
        text: 'Spostamento',
        title: 'Spostamenti',
    },
    trekking : {
        sort: 4,
        icon: star_img,
        color: 'green',
        text: 'Tappa Naturalistica',
        title: 'Passeggiate',
    },
    poi : {
        sort: 5,
        icon: star_img,
        color: 'blue',
        text: 'Tappa Culturale',
        title: 'Punti di interesse',
    },
    hotel : {
        sort: 6,
        icon: bed_img,
        color: 'yellow',
        text: 'Pernottamento',
        title: 'Alberghi e strutture',
    },
}