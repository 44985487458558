import clsx from 'clsx';
import React from 'react';
import x  from '../assets/images/x-social.svg'
import fb from '../assets/images/fb-social.svg'
import ig from '../assets/images/ig-social.svg'
import { useLS } from '../contexts/LsContext.js';
import { useLayout } from '../contexts/layoutContext.js';
import { useItinerary } from '../contexts/itineraryContext.js';
import { useWizard } from '../contexts/wizardContext.js';
import { Link, navigate } from "gatsby";
import { useChat } from '../contexts/chatContext.js';
import { sendToDataLayer } from '../utils/utils.js';
import { LOCAL_STORAGE_KEYS } from '../utils/CONSTANTS.js';

const Menu = ({showMenu, toggleMenu}) => {

    const LSData = useLS() || {};
    const {removeAllDataFromLS, getFromLS} = LSData

    const layoutData = useLayout() || {};
    const {setUserLocation, userLocation, setPlaceDetail} = layoutData

    const itineraryData = useItinerary() || {};
    const {scheduledDays, optionsItems} = itineraryData

    const chatData = useChat() || {};
    const {resetChat} = chatData

    const wizardData = useWizard() || {};
    const {wizardItems} = wizardData

    const createNewTrip = async () => {
        await removeAllDataFromLS();
        // setUserLocation(1);
        setTimeout(() => {
            document.body.classList.remove('overflow-hidden');
        }, 100);
        navigate('/wizard');
        toggleMenu();
        setPlaceDetail({})
        resetChat()

        const userLS = await getFromLS(LOCAL_STORAGE_KEYS.user);
        const session_id = userLS?.session_id;
        sendToDataLayer({
            event: 'resetApp',
            data: {
                session_id: session_id,
                option: {
                    restartedApp: true,
                    location: 'menu'
                }
            }
        })
    }

    return (
        <nav className={clsx(showMenu ? 'left-0' : 'left-[100%]', 'Menu fixed top-[62px] w-full h-full bg-light-grey z-50 transition-all')}>
            <ul className='text-right p-6'>
                {/* <li className='text-lg mb-6'>Homepage</li> */}
                
                {(scheduledDays.length>0) && (
                    <button onClick={() => {
                        navigate('/itinerary');
                        // setUserLocation(4)
                        toggleMenu()
                    }} className='block ml-auto'>
                        <li className='text-lg mb-6'>Dettagli viaggio</li>
                    </button>
                )}
                
                {(optionsItems.length>0 || scheduledDays.length>0) && (
                    <li className='text-lg mb-6'>
                        <button onClick={createNewTrip} className='block ml-auto'>
                            Nuovo viaggio
                        </button>
                    </li>
                )}
                <li className='text-lg mb-6'>FAQ</li>
                {/* <li className='text-lg mb-6'>Visit Campobasso</li> */}
            </ul>
            <ul className='px-6'>
                <li className='text-xs text-right mb-3'>
                    <a href="https://www.iubenda.com/privacy-policy/93808042" target='_blank'>Privacy Policy</a>
                </li>
                <li className='text-xs text-right mb-3'>
                    <a href="https://www.iubenda.com/privacy-policy/93808042/cookie-policy" target='_blank'>Cookie Policy</a>
                </li>
            </ul>
            <ul className='flex justify-end p-6'>
                <li className='ml-6'>
                    <img className='h-[24px]' src={x} />
                </li>
                <li className='ml-6'>
                    <img className='h-[24px]' src={fb} />
                </li>
                <li className='ml-6'>
                    <img className='h-[24px]' src={ig} />
                </li>
            </ul>

            <div className='absolute bottom-[10rem] w-full'>
                <p className='text-xs text-right p-6'> v0.22</p>
                <p className='text-xs text-right px-6'>© Tiscali Italia S.p.a 2024 P.IVA 02508100928 | © CTE Casa delle Tecnologie Emergenti di Campobasso</p>
            </div>
        </nav>
    )
}

export default Menu;