import React from 'react'
import logoCB from '../assets/images/loghi.svg';
import logoZIA from '../assets/images/logo.svg';
import hamburger from '../assets/images/hamburger.svg';
import close from '../assets/images/close.svg';
import { useLayout } from '../contexts/layoutContext.js';
import Menu from './Menu.js';



const Header = () => {

    const layoutData = useLayout() || {};
    const {showMenu, toggleMenu} = layoutData;

    // ReactGA.initialize("G-51Y8T7MCH6");

    return (
        <>
            <header className='w-full h-[64px] flex items-center text-center justify-center shadow-md fixed top-0 left-0 bg-white z-20 px-4'>
                <div className='max-w-[720px] flex items-center w-full'>
                    <div className='logoCB w-[65%]'>
                        <img className='block mr-auto' src={logoCB} />
                    </div>
                    <div className='logoZIA w-[35%] flex justify-end items-center'>
                        <img className='block mr-[24px] h-[40px]' src={logoZIA} />
                        <button onClick={toggleMenu} className='float-right block menu'>
                            <img className='block ml-auto h-[18px]' src={!showMenu ? hamburger : close} />
                        </button>
                        {/* <Link to="/test">Test</Link> */}
                    </div>
                </div>
            </header>
            <Menu toggleMenu={toggleMenu} showMenu={showMenu}/>
        </> 
    )
}

export default Header