import React, {
    createContext,
    useContext,
    useEffect,
    useState,
  } from "react";
  
  export const wizardContext = createContext();
  
  const useProvideWizard = () => {
    const [wizardItems, setWizardItems] = useState([]);

    const saveValueOrReplaceWizardItem = (data, id) => {
      const oldState = [...wizardItems];
      const existingIndex = oldState.findIndex(el => el.hasOwnProperty(id));
      if (existingIndex !== -1) {
        oldState[existingIndex] = {[id]: data};
      } else {
        oldState.push({[id]: data});
      }
      console.log('oldState', oldState);
      setWizardItems(oldState)
    }
  
    useEffect(() => {
      console.log('zio')
    }, []);
  
    return {
        wizardItems, 
        setWizardItems, 
        saveValueOrReplaceWizardItem
    };
  };
  
  export const WizardProvider = ({ children }) => {
    const wizard = useProvideWizard();
    return (
      <wizardContext.Provider value={wizard}>{children}</wizardContext.Provider>
    );
  };
  
  export const useWizard = () => useContext(wizardContext);
  