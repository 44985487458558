export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('-');
}


export const isEmptyObject = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false; // If the object has at least one own property, it is not empty
      }
    }
    return true; // If no own properties found, the object is empty
  }


export const makeId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export const generateDateRange = () => {
   let today = new Date();
   let firstDate = new Date(today.getTime() + (14 * 24 * 60 * 60 * 1000));
   let secondDate = new Date(today.getTime() + (16 * 24 * 60 * 60 * 1000));
   let firstDateString = `${firstDate.getDate()}-${firstDate.getMonth() + 1}-${firstDate.getFullYear()}`;
   let secondDateString = `${secondDate.getDate()}-${secondDate.getMonth() + 1}-${secondDate.getFullYear()}`;

   return `${firstDateString} to ${secondDateString}`;
}


export const sendToDataLayer = (payload) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    console.log('Pushing event to dataLayer');
    window.dataLayer.push(payload);
    console.log('Event pushed:', payload);
  } else {
    console.log('dataLayer is not defined');
  }
}