const React = require("react")
const { default: RootLayout } = require("./src/components/rootLayout")
const Layout = require("./src/components/layout").default

exports.onRouteUpdate = ({ location, prevLocation }) => {
    console.log("new pathname", location.pathname)
    console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  }

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <RootLayout>
        <Layout {...props}>
            {element}
        </Layout>
    </RootLayout>

  )
}