import React from "react"

const VoiceBallonDisclaimer = () => {
    return (
        <div className="absolute bottom-16 right-0 bg-orange p-4  rounded-md ziaChatAnimation">
            <p className="text-white text-xs max-w-[200px]">Puoi ascoltare la voce della zia!</p>
            <div className="arrow-down absolute -bottom-[8px] right-[16px]"></div>
        </div>
    )
}

export default VoiceBallonDisclaimer