import React, { useEffect } from 'react'
import Header from './header.js'
import { useLayout } from '../contexts/layoutContext.js'
import Chat from './chat.js'
import WorkAroundTailwind from './tailwindWorkaround.js'

const Layout = ({children}) => {

    const layoutData = useLayout() || {};
    const {userLocation} = layoutData;

    

    return (
        <>
            <Header />
            {children}
            {/* {(userLocation === 4 || userLocation === 5) && <Chat />} */}
            <WorkAroundTailwind />
        </>
    )
}

export default Layout